<template>
    <div id="orgList">
        <div class="menu_content">
            <div class="menu_content_title">
                <h4>商品类目</h4>
            </div>
            <div class="menu_content_content">
                <div class="menu_left">
                    <el-tree
                      class="filter-tree"
                      v-if="treeData.length"
                      :data="treeData"
                      :check-strictly="true" 
                      node-key="id"
                      :default-expanded-keys="expandList"
                      :highlight-current="true"
                      :props="defaultProps"
                      :filter-node-method="filterNode"
                      @node-click="chooseNode"
                      :expand-on-click-node="false"
                      ref="tree">
                      <span class="custom-tree-node" style="font-size: 14px" slot-scope="{node,data}">
                        <span class="el-icon-s-claim" style="margin-right: 4px"></span><span>{{data.categoryName}}</span>

                      </span>
                    </el-tree>
                </div>

                <div class="menu_right">
                    <el-button type="primary" style="height: 32px; padding: 0 12px" @click="add">新增类目</el-button>
                    <h6 class="mb10" >{{tableTitle}} <a v-if="isShow" @click="editMenu(leftClickData)">编辑</a>
                    <el-popconfirm
                          icon="el-icon-info"
                          icon-color="red"
                          v-if="isShow"
                          @confirm="delMenu(leftClickData)"
                          title="是否确认删除类目?"
                          >
                          <a slot="reference">删除</a>
                          <!-- <div class="mb8 cp" style="cursor: pointer" slot="reference">删除</div> -->
                      </el-popconfirm>
                    
                    
                    </h6> 
                    <!-- <h6 v-if="isShow"><span :class="chooseForm.moduleIcon"></span>{{chooseForm.orgName}} <a @click="editMenu(chooseForm)">编辑</a><a @click="delMenu(chooseForm)">删除</a></h6>

                    <p v-if="isShow"> <span>机构编码：{{chooseForm.orgCode}}</span>  <span>序列号：{{chooseForm.sort}}</span> <span>子机构数量：{{tableData.length}}</span> </p> -->

                    <div style="margin-top: 4px">
                        <el-table border :data="tableData" style="width: 100%">
                            <!-- <el-table-column
                                type="selection"
                                width="55">
                            </el-table-column> -->
                            <el-table-column prop="orgName" width="70" type="index" label="序号"></el-table-column>
                            <el-table-column prop="categoryCode" min-width="60" label="类目编号"></el-table-column>
                            <el-table-column prop="categoryName" min-width="80" label="类目名称"></el-table-column>
                            <el-table-column prop="sort" min-width="60" label="排序号"></el-table-column>
                            <el-table-column prop="attributeCount" min-width="60" label="属性数"></el-table-column>
                            <el-table-column prop="" min-width="60" label="状态">
                              <template slot-scope="scope">
                                <el-switch
                                    @change="changeStatus(scope.row)"
                                    v-model="scope.row.categoryStatus == '1' ? true : false"
                                    active-color="#13ce66"
                                    inactive-color="#ff4949">
                                </el-switch>
                              </template>
                            </el-table-column>
                            
                            <!-- <el-table-column prop="name" label="机构icon">
                              <template slot-scope="scope">
                                <span :class="scope.row.moduleIcon"></span>
                              </template>
                            </el-table-column> -->
                            <el-table-column prop="name" label="操作" align="center">
                                <template slot-scope="scope">
                                  <el-button @click="attrMenu(scope.row)" type="text" size="small">属性</el-button>
                                  <el-button @click="specificationMenu(scope.row)" type="text" size="small">规格</el-button>
                                  <el-button @click="editMenu(scope.row)" type="text" size="small">编辑</el-button>
                                  <el-popconfirm
                                    icon="el-icon-info"
                                    icon-color="red"
                                    @confirm="delMenu(scope.row)"
                                    title="是否确认删除类目?"
                                    >
                                    <el-button type="text" slot="reference" style="margin-left: 12px" size="small">删除</el-button>
                                </el-popconfirm>
                                  
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div style="float: right; margin-top: 10px">
                        <!-- <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="pageInfo.pageNo"
                            :page-sizes="[10, 20, 50, 100]"
                            :page-size="pageInfo.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="pageInfo.total">
                            </el-pagination> -->
                    </div>
                </div>
            </div>
        </div>



        <el-dialog :title="dialogTitle" width="40%" :visible.sync="dialogFormVisible">
            <el-form :model="form" ref="cdForm" :rules="rules" style="width: 80%; margin: 20px auto 0;" label-width="80px">
                <el-form-item label="类目编号" prop="categoryCode">
                    <el-input  :disabled="dialogTitle == '新增类目'? false: true" v-model="form.categoryCode" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="类目名称" prop="categoryName">
                    <el-input v-model="form.categoryName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="父级类目">
                    <el-popover
                        ref="treePop"
                        placement="bottom"
                        width="414"
                        trigger="click"
                        >
                        <!--  v-if="treeData.length" -->
                        <el-tree class="tree-scroll-cls"  :data="treeData" :expand-on-click-node="false"  :props="defaultProps" @node-click="handleNodeClick">
                            <span class="custom-tree-node" style="font-size: 14px" slot-scope="{node,data}">
                            <span class="el-icon-s-claim" style="margin-right: 4px"></span>
                            <span>{{data.categoryName}}</span>
                          </span>
                        </el-tree>
                        <el-input readonly="" :disabled="dialogTitle == '新增类目'? false: true" v-model="form.parentCategoryName" autocomplete="off" slot="reference"></el-input>
                    </el-popover>
                </el-form-item>
                <el-form-item label="排序" prop="sort">
                  <el-input-number v-model="form.sort" controls-position="right" :min="1" :max="100000"></el-input-number>
                  <!-- <el-input v-model="form.sort" type="number" autocomplete="off"></el-input> -->
                </el-form-item>
                <!-- <el-form-item label="状态" prop="categoryStatus">
                    <el-radio v-model="form.categoryStatus" label="1">启用</el-radio>
                    <el-radio v-model="form.categoryStatus" label="2">停用</el-radio>
                </el-form-item> -->
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: right">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="addMenu">确 定</el-button>
            </div>
      </el-dialog>

      <el-dialog title="属性维护" width="700px" :visible.sync="attrDialogFormVisible">
          <div class="flex f_ai_c f_jc_b mb20">
              <div class="flex f_ai_c ">
                  <div class="fs12 mr10">所属类目: </div>
                  <div class="fs14" style="color: #1991FC;">{{checkedCategoryName}}</div>
              </div>
            <div class="submit-add" @click="addAttrGroup">+ 添加属性组</div>
          </div>
          <div class="mb20" v-for="(item, index) in attrData" :key="index">
            <div class="flex">
              <div class="flex1">
                <div class="flex  f_ai_c mb10">
                    <el-input class="new-attr mr10 flex1" v-model="item.attrGroupName" placeholder="请输入属性组" type="text"></el-input>
                    <el-popconfirm
                                    icon="el-icon-info"
                                    icon-color="red"
                                    @confirm="removeAttrGroup(index)"
                                    title="是否删除此属性组?"
                                    >
                                    <span class="fs12 cp" slot="reference" style="color: #FCAC19; margin-left: 12px; cursor: pointer">删除</span>
                                </el-popconfirm>
                    
                </div>
              </div>
              <span class="fs12 cp mt12 ml20"  @click="attrOpen(index)" style="color: #FCAC19">{{item.switchType?'关闭':'打开'}}</span>
            </div>
            <div v-show="item.switchType">
              <ul >
                <li class="attr-header attr-tr flex f_jc_b">
                  <div class="attr-td" style="width: 20%;">属性名称</div>
                  <div class="attr-td" style="width: 18%;">录入方式</div>
                  <div class="attr-td" style="width: 44%;">属性值</div>
                  <!-- <div class="attr-td" style="width: 18%;">属性类别</div> -->
                  <div class="attr-td" style="width: 12%;">状态</div>
                  <div class="attr-td " style="width: 6%;">操作</div>
                </li>
              </ul>
              <ul class="attr-body">
                <!-- <li class="attr-tr flex f_jc_b" v-for="(subItem, jndex) in item.commodityCategoryAttrBOList" :key="jndex">
                  <div class="attr-td by1" style="width: 18%;">{{subItem.attrName}}</div>
                  <div class="attr-td by1" style="width: 18%;">{{subItem.inputType}}</div>
                  <div class="attr-td by1" style="width: 18%;">{{subItem.commodityCategoryAttrValues}}</div>
                  <div class="attr-td by1" style="width: 18%;">{{subItem.attributeType}}</div>
                  <div class="attr-td by1" style="width: 14%;">{{subItem.attributeStatus}}</div>
                  <div class="attr-td by1 cp" style="width: 9%;" @click="attrRemove(index, jndex)">删除</div>
                </li> -->
                
                <li  v-for="(subItem, jndex) in item.commodityCategoryAttrBOList" :key="jndex" class="attr-tr new-attr-tr flex f_jc_b" >
                  <div class="attr-td by1" style="width: 20%;">
                    <el-input class="new-attr"  v-model="subItem.attrName" type="text"></el-input>
                  </div>
                  <div class="attr-td by1" style="width: 18%;">
                    <el-select  class="new-attr" v-model="subItem.inputType" placeholder="请选择">
                      <el-option
                        v-for="(item, i) in inputTypeData"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <div class="attr-td by1" style="width: 44%;">
                    <el-input class="new-attr" :disabled="subItem.inputType=='1'? true: false" v-model="subItem.commodityCategoryAttrValues" type="text"></el-input>
                  </div>
                  <!-- <div class="attr-td by1" style="width: 18%;">
                  <el-select  class="new-attr" v-model="subItem.attributeType" placeholder="请选择">
                      <el-option
                        v-for="(item, i) in attributeTypeData "
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div> -->
                  <div class="attr-td by1" style="width: 12%;">
                    <el-select  class="new-attr" v-model="subItem.attributeStatus" placeholder="请选择">
                      <el-option
                        v-for="(item, i) in attributeStatusData"
                        :key="i"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                    </el-select>
                  </div>
                  <el-popconfirm
                                    icon="el-icon-info"
                                    icon-color="red"
                                    @confirm="attrRemove(index, jndex)"
                                    title="是否删除此属性?"
                                    >
                                    <div class="attr-td by1 cp" slot="reference" style="width: 6%; margin-left: 12px; cursor: pointer" >删除</div>
                                </el-popconfirm>
                  
                </li>
              </ul>
              <div class="new-btn" @click="addAttr(index)">
                + 新增属性
              </div>
            </div>
          </div>
          <div slot="footer" class="dialog-footer" style="text-align: right">
              <el-button @click="attrDialogFormVisible = false">取 消</el-button>
              <el-button type="primary" @click="submitAddAttr">确定</el-button>
          </div>
      </el-dialog>

      <el-dialog title="规格维护" width="700px" :visible.sync="specificationDialogFormVisible">
        <div class="flex f_ai_c f_jc_b mb20">
          <div class="flex f_ai_c ">
              <div class="fs12 mr10">所属类目: </div>
              <div class="fs14" style="color: #1991FC;">{{checkedCategoryName}}</div>
          </div>
          <div class="submit-add" @click="addSpecification">+ 添加规格项</div>
        </div>

        <div class="flex f_ai_c mb10" v-for="(item, i) in newSpecificationData" :key="i">
          <el-input class="new-attr mr10 flex1" v-model="item.specName" placeholder="请输入规格" type="text"></el-input>
          <!-- <span class="fs12 mr10 cp" style="color: #FCAC19">保存</span> -->
          <el-popconfirm
                                    icon="el-icon-info"
                                    icon-color="red"
                                    @confirm="removeSpecification(i)"
                                    title="是否删除此规格?"
                                    >
                                    <span class="fs12 cp" slot="reference" style="color: #FCAC19; margin-left: 12px; cursor: pointer">删除</span>
                                </el-popconfirm>
          
        </div>
     
        <div slot="footer" class="dialog-footer" style="text-align: right">
            <el-button @click="specificationDialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="submitAddSpecification">确定</el-button>
        </div>
      </el-dialog>
    </div>
</template>

<script>
// import json from '../../assets/json/icon.json'
export default {
    data() {
      return {
        leftClickData: {}, //点击左侧暂存数据
        attrId: '', //记录属性主键id
        checkedCategoryName: '', //选中的类目名称
        inputTypeData: [{
          value: '1',
          label: '文本输入'
        },{
          value: '2',
          label: '选择输入'
        }],
        attributeTypeData: [{
          value: '1',
          label: '基础属性'
        },{
          value: '2',
          label: '补充属性'
        }],
        attributeStatusData: [{
          value: '1',
          label: '启用'
        },{
          value: '2',
          label: '停用'
        }],
        expandList: [],
        attrData: [], //属性值
        attrArr: [], //中间存储数据
        newAttrData: [

        ],
        newSpecificationData: [],
        newAttrGroup: [{name: ''}],
        tableTitle: '商品类目',
        dialogTitle: '新增类目',
        attrDialogFormVisible: false,
        specificationDialogFormVisible: false,
        dialogFormVisible: false,
        isShow: false,
        json: require('../../assets/json/icon.json'),
        form: {
            categoryCode: '',
            categoryName: '',
            parentCategoryId: '',
            parentCategoryName: '',
            sort: '',
        },
        pageInfo: {
            pageNo: 1,
            pageSize: 10,
            total: 0
        },
        rules: {
          categoryCode:  {required: true, message: '请输入类目编号', trigger: 'blur'},
          categoryName: {required: true, message: '请输入类目名称', trigger: 'blur'},
          sort: {required: true, message: '请选择排序', trigger: 'blur'}
        },
        chooseForm: {},
        filterText: '',
        tableData: [],
        treeData: [],
        defaultProps: {
          children: 'children',
          label: 'categoryName'
        }
      };
    },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    },
    mounted() {
      this.qryMenu();
      this.qryList();
      if (window.innerHeight){
          var winHeight = window.innerHeight;
      }
      else if ((document.body) && (document.body.clientHeight)){
          var winHeight = document.body.clientHeight;
      }
      document.getElementById("orgList").style.minHeight = (winHeight-90)+'px';
    },
    methods: {
      
      //查询所有类目
      qryMenu(){
        this.qa.queryOperateAllTreeCommodityCategoryPOs({}).then((res => {
          //this.tableTitle = res.data[0].categoryName
          // this.treeData = this.FWZX.buildTree(res.data, 'orgCode', 'parentOrgCode');
          this.treeData = res.data
          console.log(this.treeData)
        }))
      },
      qryList(){
        //   let params = {
        //       parentOrgCode: 
        //   };
          this.qa.queryOperateChildrenByParentId({}).then((res) => {
              if(res.respCode == "0000") {
                  this.tableData = res.data;
                  // this.pageInfo = res.data.total;
              } else {
                  this.$message.error(res.msg)
                }
              
          })
      },
      //新增按钮
      add(){
        this.dialogTitle = "新增类目";
        Object.keys(this.form).forEach(key => (this.form[key] = ""));
        this.form.orgStatus = "1";
        this.form.parentCategoryId = this.leftClickData.id;
        this.form.parentCategoryName = this.leftClickData.categoryName;

        this.dialogFormVisible = true;
      },
      //新增机构
      addMenu(){

        this.$refs.cdForm.validate((valid) => {
            if (valid) { 
              if(this.dialogTitle == "新增类目") {
                this.qa.doInsert(this.form).then((res) => {
                  if(res.respCode == "0000") {
                    this.$message({
                        message: '类目新增成功！',
                        type: 'success'
                    });
                    this.qryMenu();
                  } else {
                    this.$message.error(res.msg);
                  }
                  this.dialogFormVisible = false;
                })
              } else {
                this.qa.doUpdate(this.form).then((res) => {
                  if(res.respCode == "0000") {
                    this.$message({
                        message: '类目修改成功！',
                        type: 'success'
                    });
                    // if(this.form.id == this.chooseForm.id) {
                    //   this.chooseForm = this.form;
                    // }
                    this.qryMenu();
                    this.qryList();
                  } else {
                    this.$message.error(res.msg);
                  }
                  this.dialogFormVisible = false;
                })
              }
              this.expandList.push(this.form.parentCategoryId);
            } else {
              return false;
            }
        })
        
        
      },

      //选中左侧树
      chooseNode(data){
        console.log(data)
        this.tableTitle = data.categoryName;
        this.leftClickData = data;
        // this.$set("")
        this.chooseForm = data;
        this.isShow = true;
        // this.qa.queryOperateChildrenByParentId({parentCategoryId: data.id, pageNo: this.pageInfo.pageNo, pageSize: this.pageInfo.pageSize}).then((res) => {
        this.qa.queryOperateChildrenByParentId({parentCategoryId: data.id, }).then((res) => {
          if(res.respCode == "0000") {
            this.tableData = res.data;
            this.pageInfo.total = res.total;
          } else {
            this.$message.error(res.msg);
          }
        })
      },

      //修改机构
      editMenu(row){
        console.log(row)
        this.form = {
            id: row.id,
            categoryCode: row.categoryCode,
            sort: row.sort,
            parentCategoryId: row.parentCategoryId,
            categoryName: row.categoryName,
            parentCategoryName: row.parentCategoryName
        };
        this.dialogFormVisible = true;
        this.dialogTitle = "修改类目";
      },
      //删除机构
      delMenu(row){
          this.qa.doLogicDeleteById({id: row.id}).then(res => {
            this.$message({
              message: '删除成功',
              type: 'success'
            });
            this.qryMenu();
            this.qa.queryOperateChildrenByParentId({parentCategoryId: row.parentCategoryId, }).then((res) => {
              if(res.respCode == "0000") {
                this.tableData = res.data;
                this.pageInfo.total = res.total;
              } else {
                this.$message.error(res.msg);
              }
            })
          })


      },
      //属性
      attrMenu(row) { 
        console.log(row.categoryName)
        this.checkedCategoryName = row.categoryName
        this.attrDialogFormVisible = true;
        this.attrId = row.id;
        this.getAttrList()
      },
      //添加属性组
      addAttrGroup() {
        var obj = {
          attrGroupName: '',
          switchType: true,
          commodityCategoryAttrBOList: []
        }
        this.attrData.push(obj)
      },
       removeAttrGroup(i) {
            this.attrData.splice(i, 1)

        },
        //打开收起
        attrOpen(i){
            this.attrData[i].switchType = !this.attrData[i].switchType
        },
      // 通过属性id 获取属性列表
      getAttrList() {
        this.qa.queryForCommodityCategoryAttrGroupListByCategoryId({categoryId: this.attrId}).then(res => {
          if(res.respCode == "0000") {

             this.attrData = res.data
             for (var i=0; i<this.attrData.length; i++) {
               this.attrData[i].switchType = true
             }
             console.log(this.attrData)
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      //删除属性
      attrRemove(i, j) {
          this.attrData[i].commodityCategoryAttrBOList.splice(j, 1)

        
      },
      //打开规则 //获取规则
      specificationMenu(row) {
        this.checkedCategoryName = row.categoryName;
        this.attrId = row.id;
        this.specificationDialogFormVisible = true;
        this.getSpecification()
      },
      //获取规则
      getSpecification() {
        this.qa.queryCommodityCategorySpecListByCategoryId({categoryId: this.attrId}).then(res => {
          if(res.respCode == "0000") {
             this.newSpecificationData = res.data
             console.log(this.newSpecificationData)
          } else {
            this.$message.error(res.msg)
          }
        })
      },
      
      //添加属性
      addAttr(i) {
        var attr = {
            attrName: '',
            inputType: '1',
            commodityCategoryAttrValues: '',
            // attributeType: '1',
            attributeStatus: '1'
          }
          console.log(this.attrData, i)
          this.attrData[i].commodityCategoryAttrBOList.push(attr)
      },
      //确定创建属性
      submitAddAttr() {
          if(this.attrData.length < 1) {
            this.$message.error('没有属性组不可保存！');
            return;
          }
          for(var i=0; i<this.attrData.length; i++) {
            this.attrData[i].categoryId = this.attrId;
            if(this.attrData[i].attrGroupName == '') {
              this.$message.error('请填写属性组名称');
              return
            }
            if(this.attrData[i].commodityCategoryAttrBOList.length > 0) {
              for(var j=0; j<this.attrData[i].commodityCategoryAttrBOList.length; j++) {
                console.log(this.attrData[i].commodityCategoryAttrBOList[j])
                if(this.attrData[i].commodityCategoryAttrBOList[j].attrName == '') {
                  this.$message.error('请确保新增属性信息填写完整');
                  return
                }
                if(this.attrData[i].commodityCategoryAttrBOList[j].inputType == '2') {
                  if(this.attrData[i].commodityCategoryAttrBOList[j].commodityCategoryAttrValues == ''){
                    this.$message.error('请确保新增属性信息填写完整');
                    return
                  }
                }
              }
            }
          }
          this.qa.doBatchInsertCommodityCategoryAttrGroup({id: this.attrId, commodityCategoryAttrGroupBOList: this.attrData}).then(res => {
            this.$message({
              message: '创建成功',
              type: 'success'
            });
            this.attrDialogFormVisible = false;
            this.getAttrList()
          })
        },
        
   
      //新增规格
      addSpecification() {
        var obj = {
          specName: '',
        }
        this.newSpecificationData.push(obj)
        
      },
      //删除新增规格
      removeSpecification(i) {
          this.newSpecificationData.splice(i, 1)

        
      },
      //确定创建规格
      submitAddSpecification() {
        for (var i=0; i<this.newSpecificationData.length; i++) {
          this.newSpecificationData[i].categoryId = this.attrId;
          if(this.newSpecificationData[i].specName == '') {
            this.$message.error('请填写完整规则');
            return
          }
        }
        this.qa.doBatchInsertCommodityCategorySpec({id: this.attrId, commodityCategorySpecBOList: this.newSpecificationData}).then(res => {
            this.$message({
              message: '创建成功',
              type: 'success'
            });
            this.getAttrList()
          })
      },
      handleNodeClick(data){
        console.log(data.categoryLevel)
        if(data.categoryLevel == 3) {
            this.$message.error('父节点不可选第三级');
            return
        }
        this.form.parentCategoryName = data.categoryName;
        this.form.parentCategoryId = data.id;
        this.$refs.treePop.doClose()

      },
      filterNode(value, data) {
        if (!value) return true;
        return data.label.indexOf(value) !== -1;
      },
      chooseIcon(item){
        this.form.moduleIcon = item;
      },
      handleSizeChange(page){
          this.pageInfo.pageSize = page;
      },
      handleCurrentChange(page) {
          this.pageInfo.pageNo = page;
      },
      // 列表 启用禁用
      changeStatus(row){
        console.log(row)
        let flag = 1
        if(row.categoryStatus == 1) {
            flag = 2;
        } else {
            flag = 1;
        }
        this.qa.doSwitchCommodityCategoryStatus({id: row.id, categoryStatus: flag}).then(res => {
          this.qa.queryOperateChildrenByParentId({parentCategoryId: this.chooseForm.id, }).then((res) => {
            if(res.respCode == "0000") {
              this.tableData = res.data;
              this.pageInfo.total = res.total;
            } else {
              this.$message.error(res.msg);
            }
          })
        })
      },
    },
    
}
</script>

<style scoped>
  .menu_title {
    width: 100%;
    height: 68px;
    border: 1px solid #0091FF;
    background: rgba(25, 145, 252, 0.1);
    padding: 12px 20px;
    display: flex;
    box-sizing: border-box;
  }
  .descrip {
      font-size: 14px;
      color: #1991FC;
      flex-shrink: 0;
      width: 100px;
  }
  .descrip_content {
      font-size: 14px;
      color: rgba(29, 30, 31, 0.6);
      line-height: 14px;
  }
  .descrip_content >p {
      margin-bottom: 12px;
  }
  .menu_content {
      width: 100%;
      background: #fff;
      margin-top: 12px;
  }
  .menu_content_title {
      height: 50px;
      border-bottom: 1px solid #EFEFEF;
      position: relative;

  }
  .menu_content_title >h4 {
    position: absolute;
    height: 36px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    line-height: 36px;
    left: 18px;
    bottom: 0;
  }
  .menu_content_content {
      display: flex;
  }
  .menu_left {
      padding: 10px 24px;
      width: 260px;
      flex-shrink: 0;
      border-right: 1px solid #EFEFEF;
  }
  .menu_left >>> .el-input__inner {
      height: 32px;
  }
  .menu_right {
      width: calc(100% - 260px);
      flex-shrink: 0;
      padding: 10px 24px;
  }
  .menu_right >h6 {
      font-size: 16px;
      color: #1D1E1F;
      margin-top: 30px;
  }
  .menu_right >h6 a {
      font-size: 14px;
      color: #1991FC;
      font-weight: 400;
      margin-left: 24px;
      cursor: pointer;
  }
  .menu_right >p {
      margin-top: 10px;
      font-size: 14px;
      color: rgba(29, 30, 31, 0.6);
  }
  .menu_right >p >span {
      margin-right: 20px;
  }
  .attr-header {
    width: 100%;
    margin: 0 auto;
    background: #F8F8F8;
    border-radius: 4px;
    
  }
  .attr-tr {
    height: 44px;
    line-height: 44px;
    text-align: left;
    box-sizing: border-box;
    padding-left: 1%;
  }
  .attr-tr >>> .el-input__inner {
    height: 36px;
    line-height: 36px;
  }
  .new-attr {
    width: 94%;
    
  }
  .new-attr-tr {
    background: #F8F8F8;
  }
  .new-btn {
    width: 100%;
    height: 36px;
    line-height: 36px;
    margin-top: 12px;
    text-align: center;
    color: #1991FC;
    font-size: 14px;
    cursor: pointer;
    border: 1px dashed #1991FC;
  }
  .attr-body {
  /*  max-height: 400px;
    overflow: hidden;
    overflow-y: auto;*/
  }
  .submit-add {
    width: 105px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: 14px;
    color: #1991FC;
    border: 1px solid #1991FC;
    border-radius: 4px;
    cursor: pointer;
  }
  .tree-scroll-cls{
    height: 260px;
    overflow-y: auto;
  }
</style>